/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

.elohn-flex {
	&-row {
		flex-direction: row;
		display: flex;

	}
	&-row-wrap {
		flex-flow: row wrap;
		display: flex;
	}

	&-column {
		flex-direction: column;
		display: flex;
	}
	&-column-wrap {
		flex-flow: column wrap;
		display: flex;
	}

	&-align-start-space-between,
	&-align-space-between-start
	{
		justify-content: start;
		align-items: stretch;
		align-content: space-between;
	}

	&-align-stretch-start
	{
		justify-content: start;
		align-items: stretch;
		align-content: stretch;
	}

	&-align-space-between-stretch {
		justify-content: space-between;
		align-items: stretch;
		align-content: stretch;
		// max-width: 100% if flex-direction: column; else max-height: 100% (see below)
	}

	&-align-space-between-baseline {
		justify-content: space-between;
		align-items: baseline;
		align-content: baseline;
	}

	&-align-baseline {
		align-items: baseline;
		align-content: baseline;
	}

	&-align-space-between-center {
		justify-content: space-between;
		align-items: center;
		align-content: center
	}

	&-basis {
		flex: 1 0 180px;
		min-width: 180px !important;
	}

	&-auto {
		flex: 1 1 auto;
	}

	&-full {
		flex: 1 1 100%;
	}
}

.elohn-flex-row.elohn-flex-align-space-between-stretch,
.elohn-flex-row-wrap.elohn-flex-align-space-between-stretch {
	max-height: 100%;
}

.elohn-flex-column.elohn-flex-align-space-between-stretch,
.elohn-flex-column-wrap.elohn-flex-align-space-between-stretch {
	max-width: 100%;
}
