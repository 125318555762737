/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

@use '@angular/material' as mat;

@include mat.all-component-typographies();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url('../assets/material-icons/MaterialIcons-Regular.woff') format('woff'), // IE11
	url('../assets/material-icons/MaterialIcons-Regular.woff2') format('woff2');
}

// IE11 hack (sonst sind die Icons zu weit unten und das Input Feld wächst in der Höhe)
/*
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
.mat-form-field-appearance-fill .mat-form-field-suffix {
	align-self: flex-start !important;
}

.material-icons {
	//noinspection CssNoGenericFontName
	font-family: 'Material Icons'; /* stylelint-disable font-family-no-missing-generic-family-keyword */
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

/* You can add global styles to this file, and also import other style files */
// Plus imports for other components in your app.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$elohn-app-accent: mat.m2-define-palette(mat.$m2-blue-grey-palette, 500, 200, 800);
$elohn-app-primary: mat.m2-define-palette(mat.$m2-blue-grey-palette, 500, 200, 800);

// The warn palette is optional (defaults to red).
$elohn-app-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);

// Create the theme object (a Sass map containing all of the palettes).
$elohn-app-theme: mat.m2-define-light-theme((
	color: (
		primary: $elohn-app-primary,
		accent: $elohn-app-accent,
		warn: $elohn-app-warn
	)
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($elohn-app-theme);

@mixin mat-icon-size($size: 24px) {
	font-size: $size;
	height: $size;
	width: $size;
}


.mat-icon {
	@include mat-icon-size(24px);

	&.mat-icon-20 {
		@include mat-icon-size(20px);
	}

	&.inline {
		vertical-align: bottom;
	}
}

.mat-expansion-panel-header {
	font-size: 15px;
	font-weight: 400;
}

.mat-accordion-narrow {
	.mat-expansion-panel-header {
		padding: 0 12px 0 8px;
	}

	.mat-expansion-panel-body {
		padding: 0 8px 16px;
	}
}


.mat-body, .mat-body-2, .mat-typography {
	letter-spacing: normal;
}

.mat-headline-3, .mat-typography .mat-headline-3 {
	letter-spacing: -0.005em;
	font-size: 45px;
	line-height: 48px;
}

.mat-h2, .mat-headline-6, .mat-typography .mat-h2, .mat-typography .mat-headline-6, .mat-typography h2 {
	letter-spacing: normal;
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
	letter-spacing: normal;
}


.mdc-button {
	letter-spacing: normal;
}

// Dialog
.mat-mdc-dialog-container .mdc-dialog__title {
	letter-spacing: normal !important;
}

.mdc-dialog__content + .mdc-dialog__actions {
	padding-bottom: 0;
}
