/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

@use '@angular/material' as mat;
@import 'constants';

@mixin lohnausweis-form-field() {
	mat-form-field.mat-mdc-form-field.mat-form-field-appearance-fill {
		//max-width: calc(100% - 10px);
		// die Breite darf nicht veraendert werden, da die Breite relativ zum Parent ist und damit
		// zu unerwartetem Effekten fuehrt:
		// Wenn das Feld direkt in eine flex-row eingesetzt wird, hat es ploetzlich die volle Breite!
		width: 100%;
	}
}

@mixin toggle-button($gap: 8px) {
	.toggle {
		&.mat-mdc-button {
			min-width: 65px;
			/*
 TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
 */
			::ng-deep .mat-button-wrapper {
				display: flex;
				flex-direction: column;
			}
		}

		&-wrapper {
			position: absolute;
			right: $gap;
			top: $gap;
			display: flex;
			flex-direction: column;
		}

		&-icon {
			font-size: 28px;
		}

		&-text {
			font-size: 10px;
			line-height: 11px;
			text-align: center;
			white-space: normal;
		}

		@include mat.elevation(2);

		height: 72px;
		width: 57px;
		border-radius: 2px;
		background-color: $elohn-action-background;
		margin-bottom: $gap;
		padding: 9px 8px;
		border: 0;
		color: #ffffff;
	}
}
