/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */
@use '@angular/material' as mat;
@import 'styles/material-design';
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/elohn-dialog';
@import 'styles/elohn-flex';
@import 'styles/constants';

body {
	margin: 0;
}

:root {
	--ng-progress-color: green;
}

.aria-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.spacer-right {
	margin-right: $elohn-spacer;
}

.spacer-left {
	margin-left: $elohn-spacer;
}
