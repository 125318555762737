/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */
@use '@angular/material' as mat;
@use "sass:map";

$elohn-background: #ffffff;
$elohn-color: #000000;

// http://mcg.mbitson.com/#!?boxpalette=%23607d8b
$elohn-action-palette: (
	50 : #eceff1,
	100 : #cfd8dc,
	200 : #b0bec5,
	300 : #90a4ae,
	400 : #78919c,
	500 : #607d8b,
	600 : #587583,
	700 : #4e6a78,
	800 : #44606e,
	900 : #334d5b,
	A100 : #abe1ff,
	A200 : #78ceff,
	A400 : #45bcff,
	A700 : #2bb3ff,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #000000,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #000000,
		A700 : #000000,
	)
);
$elohn-action: mat.m2-define-palette($elohn-action-palette, $lighter: 200);

// Hintergrundfarbe für Dinge, die der User klicken kann oder geklickt hat (z.B. Buttons, Lohnausweis-Auswahl, ...)
$elohn-action-background: map.get($elohn-action, default);

//$elohn-box-field-background: #b0bec5;
$elohn-box-field-background: map.get($elohn-action, lighter);
$elohn-action-icon-color: $elohn-color;
$elohn-action-icon-color-light: map.get($elohn-action, lighter);

$elohn-spacer: 8px;
