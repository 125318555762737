/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/*
HowTo:
1. Go to https://fonts.google.com/specimen/Roboto (or any other font you want)
2. Click "SELECT THIS FONT"
3. Click "Family Selected" to open a dialog
4. Click on "CUSTOMIZE" and choose the font styles you want (bold, italic, regular, etc.)
5. Click on "EMBED" and open the URL, e.g. https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i
6. Add the content to this file
7. Download the font files and add them to src/assets/fonts
8. Replace the urls in this file with local versions (and adjust the format)
 */

/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: local('Roboto Italic'), local('Roboto-Italic'), url('../assets/fonts/Roboto-Italic.ttf') format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'), local('Roboto-Regular'), url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
