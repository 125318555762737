/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */
@use '@angular/material' as mat;
@import '../styles/constants';

.elohn-dialog-form-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0 30px 10px;

	.mat-icon {
		font-size: 20px;
	}

	mat-form-field.mat-mdc-form-field,
	mat-radio-button.mat-mdc-radio-button {
		flex: 1;
		margin: 0 5px;
	}

	mat-form-field.mat-mdc-form-field {
		margin-left: 5px;
	}

	// Damit es auch in IE11 funktioniert... (ohne :first-of-type setzt der 5px von weiter oben.)
	// stylelint-disable-next-line no-descending-specificity
	mat-icon + mat-form-field.mat-mdc-form-field,
	mat-icon + mat-form-field.mat-form-field:first-of-type {
		margin-left: 24px;
	}

	mat-form-field.mat-mdc-form-field:first-of-type {
		margin-left: 48px;
	}

	.mdc-text-field--filled:not(.mdc-text-field--disabled) {
		background-color: #ffffff00;
	}

	/*
 TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
 */
	mat-radio-group.mat-mdc-radio-group {
		flex: 1;
		display: flex;
	}
}

.dialog-h1-title {
	font: 400 24px/32px Roboto,Helvetica Neue,sans-serif;
}

.mat-mdc-dialog-container .mdc-dialog__content ul {
	font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
	color: #000000de;
}

.elohn-dialog-actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 8px 24px 24px 16px;

	.mat-mdc-raised-button,
	.mat-mdc-button {
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-weight: 500;
	}

	.mat-mdc-button {
		color: $elohn-action-background;
	}
}

.elohn-fieldset {
	margin-bottom: 2em;
	border: 0;
}
